<template>
  <nav class="navbar is-fixed-top has-shadow"  :class=" sticky ? 'has-background-white' : 'is-transparent' " role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <h1 class="header-app__title title is-3 has-text-black ml-3">Lotti</h1>
      </a>
    </div>

    <div id="navbar-start" class="navbar-menu">
      <div class="navbar-start">
        <!-- search-->
        <!-- <input class="input my-2 ml-5" type="text" placeholder="Medium input"> -->
        <!--// search-->
        <widget-time location="Switzerland" timeZone="Europe/Zurich"/>

      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">

            <new-project-button v-if="can('project-create')" class="mb-0 mr-6 is-link">New Project </new-project-button>

            <!-- <a class="button is-white is-normal">
              <unicon name="bell" fill="#000" width="32" height="32"/>
            </a> -->

            <div class="user">
              <figure class="image is-32x32">
                <img class="is-rounded" :src=" userAvatar ? userAvatar : 'https://bulma.io/images/placeholders/256x256.png'" :alt="currentUser.name">
              </figure>
            </div>


          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import { defineAsyncComponent, ref } from "vue";
import router from "@/router";
import useAuth from "@/use/useAuth";
import { useUser } from "@/use/useUser";

export default {
  name: "HeaderApp",
  props: {
    sticky: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NewProjectButton: defineAsyncComponent(() => import('@/components/projects/create/Button')),
    WidgetTime: defineAsyncComponent(() => import('@/components/widgets/localTime')),
  },
  setup() {

    const { clearToken, setAuthenticated } = useAuth()
    const { currentUser, can } = useUser()
    const userAvatar = ref('')

    if ( localStorage.getItem('avatar_url') ) {
      userAvatar.value = localStorage.getItem('avatar_url')
    }


    return {
      can,
      userAvatar,
      currentUser
    }
  }
}
</script>


<style lang="scss" scoped>
.header {
  background: #fff;

  &-app {
    &__title {
      font-weight: bold;
    }
  }
}

.navbar {
  left: 80px;

  &-end {
    .buttons {
      margin-bottom: 0;

      a {
        margin-bottom: 0;
      }
    }
  }

  &.is-transparent {
    background: transparent;
  }

  &-item {
    .user {
      border: 2px solid #fff;
      border-radius: 100%;

      img {
        max-height: none;

      }
    }
  }
}

.button-wrapper{
  .button {
    background: transparent;
    border-color: transparent;
  }
}
</style>
